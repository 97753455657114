@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --color-1: 0 85% 61%;    /* Red */
  --color-2: 255 85% 61%;  /* Blue */
  --color-3: 38 85% 61%;   /* Orange */
  --color-4: 170 85% 61%;  /* Cyan */
  --color-5: 318 85% 61%;  /* Pink */
}

.dark {
  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;
}

@keyframes rainbow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animate-rainbow {
  animation: rainbow 8s linear infinite;
}
